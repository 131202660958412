import { useData } from "../../component/Dataprovider";
import { saveAs } from 'file-saver';
import MainConainer from "../../component/MainContainer";
import Resume from "../../assets/doc/RESUME.pdf"

export default function Home({ page }) {
    const { textColor, isLightMode } = useData()

    const handleDownload = () => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
            window.open(Resume, '_blank');
        } else {
            saveAs(Resume, 'RESUME.pdf');
        }
    };

    return (
        <MainConainer page={page}>
            <div className="flex flex-col gap-5 justify-between h-full pb-10">

                <div className={`text-sm lg:text-md items-center ${textColor}`} >
                    Hey there! I am Shams Kibonge, a passionate web and mobile developer. I graduated with a degree in Computer Science in 2022 from Université Nouveaux Horizons in the Democratic Republic of Congo, I swiftly transitioned into the role of an analyst web developer, where I fine-tuned my programming and debugging skills. Coding is not just a job for me; it is a love affair with innovation and self-improvement. Each line of code I write is a step forward in my journey to master the digital domain. I thrive on challenges and I am always eager to learn and grow. Let us embark on this exciting journey together, where creativity knows no bounds and the possibilities are endless. Welcome to my world of code!
                </div>

                <div className={`${isLightMode ? 'text-green-700' : 'text-green-400'} text-xs hover:underline cursor-pointer`} onClick={handleDownload}>
                    Click here to download my RESUME.pdf
                </div>
            </div>
        </MainConainer>
    )
}