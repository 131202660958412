import { FaNodeJs, FaReact, FaWrench } from "react-icons/fa";
import MainConainer from "../../component/MainContainer";
import { BsDatabase } from "react-icons/bs";
import { useData } from "../../component/Dataprovider";
export default function Expertise({ page }) {
    const { textColor } = useData()

    const Item = ({ Icon, title, details }) => {
        return (
            <div className="border-4 rounded p-2">
                <div className="flex items-center gap-2 font-bold">
                    <Icon size={35} /> <div className="">{title}</div >
                </div>
                <div className={textColor}>
                    {details}
                </div>
            </div>
        )
    }
    return (
        <MainConainer page={page}>
            <div className="flex flex-col justify-center">
                {/* <div className="underline font-bold uppercase">
                    Expertise
                </div> */}
                <div className="grid grid-cols-1 lg:grid-cols-2 justify-center">
                    <Item
                        Icon={FaReact}
                        details={' Enthusiastic about UI/UX design. Possessing more than a year of hands-on experience in developing with HTML, CSS, JS, ReactJS, and NextJS frameworks.'}
                        title={'Frontend Dev ReactJs'}
                    />
                    <Item
                        Icon={FaNodeJs}
                        details={'Passionate about backend development. With over a year of experience, adept in Node.js, Express.js, and other related technologies for building robust server-side applications.'}
                        title={'Backend Dev NodeJs'}
                    />
                    <Item
                        Icon={BsDatabase}
                        details={'Enthusiastic about database management. Possessing extensive experience in designing, implementing, and optimizing databases using SQL, NoSQL, and related technologies.'}
                        title={'Database Management'}
                    />
                    <Item
                        Icon={FaWrench}
                        details={'Passionate, Recent graduate in network engineering, eager to apply theoretical knowledge to real-world scenarios. Proficient in network protocols and eager to gain hands-on experience in building robust infrastructures.'}
                        title={'Network Engineer'}
                    />

                </div>

            </div>
        </MainConainer>
    )
}